<template>
  <validation-provider :name="label" :rules="rules" v-slot="validationContext">
    <b-form-group :label="label" :label-for="id">
      <b-form-input :name="id" hidden :state="getValidationState(validationContext)" :aria-describedby="`${id}-feedback`" :id="id" :value="value"></b-form-input>
      <v-select
        ref="formSelect"
        :disabled="disabled"
        :append-to-body="appendToBody"
        :placeholder="placeholder"
        :components="{Deselect}"
        @search:focus="openSelect"
        @option:selected="changed"
        @option:deselected="deselected"
        :class="[{'invalid': validationContext.errors[0]},classSize]"
        :value="value"
        @input="handleInput"
        :options="data"
        label="label"
        :reduce="e => e.value"
        @search="onSearchData"
        :clearable="clearable"
      >
        <template v-slot:no-options="{search,searching}">
          <template v-if="searching">
            Tidak ada {{label}} ditemukan untuk "<em>{{search}}</em>".
          </template>
          <em style="opacity:0.8;" v-else>Ketik untuk mulai mencari {{label}}.</em>
        </template>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">🔽</span>
        </template>
      </v-select>
      <b-form-invalid-feedback :id="`${id}-feedback`">{{validationContext.errors[0]}}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import id from "vee-validate/dist/locale/id.json"
import { localize, extend, ValidationProvider } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";

import {BFormGroup,BFormInvalidFeedback,BFormInput} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import axios from '@axios'
import { v4 as uuidv4 } from 'uuid'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('id', id)

export default {
  props:{
    label:{
      required: true,
      type: String
    },
    placeholder:{
      required: false,
      type: String
    },
    size:{
      required: false,
      type: String
    },
    url:{
      required: false,
      type: String
    },
    value:{
      required: false,
    },
    appendToBody:{
      type: Boolean,
      default: false
    },
    clearable:{
      type: Boolean,
      default: false
    },
    disabled:{
      type: Boolean,
      default: false
    },
    rules:{
      required: false,
      type: Object
    },
    options:{
      required: false,
      type: Array,
      default: () => {
        return []
      }
    },
    queryparams:{
      type:Object
    },
    filterId:Array
  },
  data(){
    return {
      Deselect:{
        render: createElement => createElement('span',{
          on:{
            click:()=>{
              this.deselected(this)
            }
          }
        }, '✖️')
      },
      search:'',
      data: this.options,
      id: ""
    }
  },
  computed:{
    classSize(){
      if (!this.size) {
        return ''
      } else {
        return 'select-size-'+this.size
      }
    }
  },
  components:{
    BFormGroup,
    vSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormInput
  },
  methods:{
    changed(val){
      this.$emit('value-changed',val)
    },
    deselected(){
      this.$emit('value-changed',{})
    },
    handleInput(value){
      this.$emit('input', value)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    initOption(value){
      this.data = value
    },
    openSelect(){
      // console.log(this.$refs.formSelect)
      // this.onSearchData("",true)
      this.loadData()
    },
    async loadData(search=''){
      if(!this.url) return Promise.resolve(this.data)
      try {
        let { data:result } = await axios.get(this.url,{params:{name:search,...this.queryparams}})
        let data = result
        if(this.filterId && !this.isPusat) data = _.filter(result, o => _.includes(this.filterId, o.value))
        this.$emit('ajax-result', data)
        let options = []
        const selected = this.data.filter(d => d.value==this.value)
        if(selected.length>0) options = _.uniqBy(_.flattenDeep([data,selected]) ,'value')
        else options = data
        this.data = options
        return Promise.resolve(options)
      } catch (error) {
        this.handleError(error)
        return Promise.reject(error)
      }
    },
    onSearchData(search,loading){
      if(search.length){
        loading(true);
        this.searchData(loading,search,this)
      }
    },
    searchData: _.debounce((loading,search,vm) => {
      vm.loadData(search).then(() => {
        loading(false)
      })
    },350),
  },
  created(){
    this.id = uuidv4()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .invalid .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .valid .vs__dropdown-toggle {
    border-color: #28c76f;
  }
</style>